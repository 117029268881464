import React, { useContext } from 'react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconGeneric, IconMenuRUD } from 'styled/Icons.styled'
import { MdDelete, MdRemoveRedEye } from 'react-icons/md'
import {
  HiDocumentRemove,
  HiDocumentAdd,
  HiDotsHorizontal
} from 'react-icons/hi'
import { RiEdit2Fill } from 'react-icons/ri'

import { AiOutlineUserAdd } from 'react-icons/ai'
import ConfigurationCRUDContext from 'context/ConfigurationCRUDContext'
import { HrDividerNoMargin } from 'styled/Line.styled'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

const MenuRUD = ({
  data,
  deleteField = true,
  licenses = false,
  document = false,
  remove = false
}) => {
  const {
    onOpenEdit,
    setDataToEdit,
    onOpenDelete,
    setDataToDelete,
    onOpenShow,
    setDataToShow,
    setDataToLicenses,
    onOpenLicenses,
    onOpenDocument,
    setDataToDocument,
    onOpenRemove,
    setDataToRemove
  } = useContext(ConfigurationCRUDContext)
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)
  const navigate = useNavigate()

  const handleEdit = e => {
    setDataToEdit(data)
    if (location.pathname === '/config/roles') {
      return navigate('/config/manageRoles?movement=Edit')
    }

    onOpenEdit()
  }

  const handleDelete = e => {
    const { id: _, ...rest } = data
    rest.id = data?.subheading_id ||  data?.id

    setDataToDelete(rest)
    onOpenDelete()
  }

  const handleShow = e => {
    setDataToShow(data)
    onOpenShow()
  }

  const addLicenses = e => {
    setDataToLicenses(data)
    onOpenLicenses()
  }

  const addDocument = e => {
    setDataToDocument(data)
    onOpenDocument()
  }

  const removeDocument = e => {
    setDataToRemove(data)
    onOpenRemove()
  }

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color='#00000085' />
      </MenuButton>

      <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
        <MenuItemStyled py={3} onClick={handleShow}>
          <IconMenuRUD color='green' as={MdRemoveRedEye} />
          Ver
        </MenuItemStyled>

        <HrDividerNoMargin />

        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>

        {movement !== 'Tipo de Documentos' && (
          <>
            <HrDividerNoMargin />
            {deleteField && (
              <MenuItemStyled py={3} onClick={handleDelete}>
                <IconMenuRUD color='red' as={MdDelete} />
                Eliminar
              </MenuItemStyled>
            )}
          </>
        )}

        {licenses && (
          <>
            <HrDividerNoMargin />
            <MenuItemStyled py={3} onClick={addLicenses}>
              <IconMenuRUD as={AiOutlineUserAdd} />
              Asignar Licencia
            </MenuItemStyled>
          </>
        )}

        {document && (
          <>
            <HrDividerNoMargin />
            <MenuItemStyled py={3} onClick={addDocument}>
              <IconMenuRUD as={HiDocumentAdd} />
              Asignar operación
            </MenuItemStyled>
          </>
        )}
        {remove && (
          <>
            <HrDividerNoMargin />
            <MenuItemStyled py={3} onClick={removeDocument}>
              <IconMenuRUD as={HiDocumentRemove} />
              Desasociar documento
            </MenuItemStyled>
          </>
        )}
      </MenuList>
    </Menu>
  )
}

export default MenuRUD
