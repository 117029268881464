/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import {
  AccordionPanel,
  Badge,
  FormControl,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import Logout from "components/Logout/Logout";
// import Profile from "components/Profile/Profile";
import { AuthContext } from "context/AuthContext";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  MdKeyboardArrowDown,
  MdNotifications,
  MdQuestionAnswer,
} from "react-icons/md";
import { GiWorld } from "react-icons/gi";
import { Notification } from "./Drawer/Notification";
import {
  HeaderIcon,
  HeaderSection,
  HeaderStyled,
  TitleBalance,
  TrmSection,
  ValueBalance,
  ProfileItem,
  TitleProfile,
} from "./Header.styled";
import { Breadcrumbs } from "components/Breadcrumbs";
import { breadCrumbRoute } from "routes/breadCrumbRoute";
import { AccordionSimple } from "components/Accordion/AccordionSimple";
import { useDispatch, useSelector } from "react-redux";
import {
  __Get,
  __GetData,
  __GetDataId,
  __GetDataNoPage,
} from "request/Petitions/__Get";
import environment from "constants/apiConst";
import "./Stile.css";
import { __Trm } from "request/configuration/__Trm";
import { setDataStatusTrm } from "redux/actions/statusTrmaction";
import Echo from "laravel-echo";
// import { broadcaster } from 'laravel-echo-api-gateway'
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { ConfigNotification } from "./Drawer/ConfigNotification";
import { useModal } from "hooks/useModal";
import { __Me } from "request/__Me";
import { BalanceInterval } from "./BalanceInterval";
import { handleLogout } from "components/Logout/FuncionLogout";
// import { login } from "pages/Home/Auth/HomeScreen/Login/functionLogin";
// import { FirstLoginContext } from "context/FirstLoginContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import GlobalContext from "context/GlobalContext";
import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { setDataUser } from "redux/actions/useAction";
import updateFreeZone from "redux/actions/freeZoneActions";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: "pusher",
  key: window.wsappkey
    ? window.wsappkey
    : "88f0d4f3-822e-4dae-8046-1f45dae442b7",
  wsHost: window.wshost,
  wsPort: 6001,
  wssHost: window.wshost,
  wssPort: 6001,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  authEndpoint: environment.PUSHER_AUTH,
  cluster: "us",
});

const chanelZF = new BroadcastChannel("zf-channel");

const Header = ({ set, state }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const idFuncionaio = useSelector((state) => state.auth.dataUser.id);
  const dataUser = useSelector((state) => state.auth?.dataUser);
  const { authUser } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [balances, setBalances] = useState(null);
  const [trm, setTrm] = useState(null);
  const [routebread, seRoutebread] = useState([]);
  const [dataBasculas, setDataBasculas] = useState(null);
  const [GetFreeZone, setGetFreeZone] = useState(null);
  const [valueWeighingMachine, setValueWeighingMachine] = useState(null);
  // const [positionCarousel, setpositionCarousel] = useState(0);
  const [newNotification, setnewNotification] = useState(null);
  const [StateReset, setStateReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const { admin, user_rol } = useSelector((state) => state.auth.dataUser);
  const { balance } = useSelector((state) => state.status);
  const { trmValue } = useSelector((state) => state.statusTrm);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [myNotification, setMyNotification] = useState(null);
  const HeaderUserImage = useSelector((state) => state?.userInfo?.update);
  const dispacthRedux = useDispatch();

  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();

  // dispacthRedux(setDataUser(data?.data?.data));
  // const [geoLocation, setgeoLocation] = useState(null);
  // const [alertMessage, setAlertMessage] = useState("");
  // const { firstLoginDispatch, openModal } = useContext(FirstLoginContext);
  const [valueFreeZone, setValueFreeZone] = useState(null);
  const [dataFreeZone, setDataFreeZone] = useState([]);
  const { userImage, setUserImage } = useContext(FormCrudCRUDContext);
  const { requestData } = useContext(GlobalContext);
  const [userPic, setUserPic] = useState("");
  const [storeKeepSesion, setstoreKeepSesion] = useState(false);
  const location = useLocation();

  const { dispatch } = useContext(AuthContext);

  const [isOpenConfi, onOpenConfi, onCloseConfi] = useModal();

  const openNoti = () => {
    onOpen();
  };

  useEffect(() => {
    if (!routebread.length) seRoutebread(breadCrumbRoute());
  }, [routebread]);

  const getBasculas = useCallback(async () => {
    try {
      const res = await __GetData(environment.GET_WEIGHING, token);
      setDataBasculas(res?.data?.status?.data);
    } catch (error) {
      console.log("header", error);
    }
  }, [token]);

  const getNotification = useCallback(async () => {
    try {
      const res = await __Get(environment.GET_NOTIFICATION, token);
      const restData = res?.data?.status;
      if (restData?.code === 200) {
        setMyNotification(restData?.data);
        const countNot = restData?.data?.filter(({ is_read }) => is_read === 0);
        if (countNot) {
          setnewNotification(countNot.length);
        }
      } else {
        if (res?.data?.status === "El token expiró.") {
          openAlert(res?.data?.status, "error");
          handleLogout(token, dispatch, dispacthRedux, navigate, idFuncionaio);
        } else {
          openAlert(restData.message, "error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const updateNotific = useCallback(
    async (id) => {
      try {
        const res = await __GetDataId(
          environment.UPDATE_NOTIFICATION,
          token,
          id
        );
        const restData = res?.data?.status;

        if (restData?.code === 200) {
          getNotification();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  const readAll = useCallback(
    async (id) => {
      try {
        const res = await __Get(environment.UPDATE_ALL_NOTIFICATION, token, id);
        const restData = res?.data?.status;

        if (restData?.code === 200) {
          getNotification();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  const restConfi = async (position) => {
    try {
      const res = await __Get(
        position === 0
          ? environment.REST_ALERT_CONFI
          : position === 1
          ? environment.REST_NOTIF_CONFI
          : environment.REST_ALERT_CONFI_EMPRESA,
        token
      );

      const restData = res?.data?.status;

      if (restData?.code === 200) {
        setStateReset(!StateReset);
      } else {
        openAlert(restData.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getNotification();
    }
  }, [getNotification, token]);

  useEffect(() => {
    if (allAccess) {
      getBasculas();
    } else if (allAccess === false && myPermission?.includes("getScales")) {
      getBasculas();
    }
    const channel = localStorage.getItem("chanelWeighing");
    setValueWeighingMachine(channel !== "" ? channel : null);
  }, [getBasculas, myPermission, allAccess]);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (user_rol?.modules) {
      const myArrayCurrent = user_rol?.modules?.filter(
        (e) => e.modul_permissionGroup === "Básculas"
      );
      const newArray = [];
      myArrayCurrent.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
      // console.log(newArray);
    }
  }, [user_rol?.modules, admin]);

  const handleChangeCheck = (e) => {
    const id = e.target.id;
    const state = e.target.checked;
    if (state) {
      setValueWeighingMachine(id);
      localStorage.setItem("chanelWeighing", parseInt(id));
    } else {
      setValueWeighingMachine(null);
      localStorage.setItem("chanelWeighing", "");
    }
  };

  useEffect(() => {
    const datos = JSON.parse(localStorage.getItem("ValueBalance"));
    setBalances(datos);
  }, [balance]);

  const getExchangeRate = useCallback(async () => {
    try {
      const res = await __Trm(token);
      if (res.data.status.code === 200) {
        dispacthRedux(setDataStatusTrm(!trmValue));
        const dataValueTrmGet = res?.data?.status?.data.value_trm;
        localStorage.setItem("ValueTrm", JSON.stringify(dataValueTrmGet));
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getExchangeRate();
  }, [getExchangeRate]);

  useEffect(() => {
    const valueTrm = JSON.parse(localStorage.getItem("ValueTrm"));
    setTrm(valueTrm);
  }, [trmValue]);

  const anchoWd = document.documentElement.scrollWidth;

  useEffect(() => {
    window.Echo.channel(`notification-${idFuncionaio}`).listen(
      ".new-notification",
      ({ dataJson }) => {
        const customId = "custom-id-yes";
        // const customId = dataJson.title + "_" + dataJson.doc_id;
        openAlert(dataJson?.description, "", "", customId);
        getNotification();
      }
    );
  }, []);

  const getPic = async () => {
    try {
      const info = await __Me(token);
      const res = info?.data?.status?.data;

      if (info?.data?.status === "El token expiró.") {
        openAlert(info?.data?.status, "error");
        handleLogout(token, dispatch, dispacthRedux, navigate, idFuncionaio);
      } else {
        setGetFreeZone(info?.data?.status?.data);
        dispacthRedux(setDataUser(res?.data_person?.image_person));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPic();
  }, [authUser]);

  const queryClient = useQueryClient();

  const {
    data: freeZoneData,
    isError,
    isSuccess,
    isFetching,
  } = useQuery({
    queryKey: ["freeZone"],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_FREE_ZONES_LOGIN,
        PropertyBody: "get",
      }),

    refetchOnWindowFocus: false,
  });

  const changeFreeZone = async (id, value) => {
    setLoading(true);
    /* Prevent fetching when select is empty */
    if (
      (value === undefined || value === null) &&
      (id === undefined || id === null)
    )
      return;
    try {
      const res = await __GetDataId(
        environment.UPDATE_ZONE_FREE_ZONE,
        token,
        id
      );
      const status = res.data.status.code;
      if (status === 200) {
        /* dispacthRedux(changeFreeZone(res.data.status.data)) */
        /* dispacthRedux(setDataStatusChangeFreeZone(!freeZoneValue)) */
        queryClient.invalidateQueries({ queryKey: ["freeZone"] });
        chanelZF.postMessage({
          data: res.data.status.data,
          value,
        });
        dispacthRedux(updateFreeZone(res.data.status.data));
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  chanelZF.onmessage = function (event) {
    dispacthRedux(updateFreeZone(event.data?.data));
    setValueFreeZone(event.data?.value);
    queryClient.invalidateQueries({ queryKey: ["freeZone"] });
  };

  useEffect(() => {
    if (isError) {
      openAlert("Ha ocurrido un error al cargar las zonas francas.", "error");
    }

    if (isSuccess && freeZoneData && isFetching === false) {
      const dataInputFreeZone = freeZoneData?.data?.status?.data?.map(
        ({ shortName_freeZone, id }) => ({
          value: id,
          label: shortName_freeZone,
        })
      );

      setDataFreeZone(dataInputFreeZone);
    }
  }, [isFetching]);

  /*   useEffect(() => {
      if (authUser?.logged !== false && authUser && GetFreeZone) {
        const payloadLogin = {
          ...authUser,
          name_freeZone: GetFreeZone?.freeZone?.name_freeZone
        }
        dispacthRedux(loginAction(payloadLogin))
      }
    }, [authUser, GetFreeZone]) */

  return (
    <>
      <Notification
        isOpen={isOpen}
        onClose={onClose}
        notif={myNotification}
        update={updateNotific}
        readAll={readAll}
        onOpenConfi={onOpenConfi}
      />

      <ModalGeneric
        isOpen={isOpenConfi}
        onOpen={onOpenConfi}
        onClose={onCloseConfi}
        title="Configuración de notificaciones"
        size="5xl"
      >
        <ConfigNotification
          onClose={onCloseConfi}
          restConfi={restConfi}
          StateReset={StateReset}
        />
      </ModalGeneric>
      <HeaderStyled wd={state === false && `${anchoWd - 270}px`}>
        <Breadcrumbs route={routebread} />
        <HeaderSection justifyContent="flex-end">
          {authUser.is_commerce === 1 && valueFreeZone ? (
            <div className="d-flex me-3 align-items-center">
              <GiWorld
                className="me-2 mt-1 icon-word"
                style={{ color: "#0063A6" }}
              />
              <h2 className="text-frezone">{valueFreeZone.label}</h2>
            </div>
          ) : authUser?.nameFreeZoneTemp ? (
            <div className="d-flex me-3 align-items-center">
              <GiWorld
                className="me-2 mt-1 icon-word"
                style={{ color: "#0063A6" }}
              />
              <h2 className="text-frezone">{authUser?.nameFreeZoneTemp}</h2>
            </div>
          ) : (
            ""
          )}

          {authUser.is_qualified_user === 1 ? (
            <div className="me-3">
              <TrmSection>
                <div className="text-center d-flex">
                  <TitleBalance weight={"700"} font={"1em"} className="resize">
                    TRM:
                  </TitleBalance>
                  <ValueBalance>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(trm)}
                  </ValueBalance>
                </div>
              </TrmSection>
            </div>
          ) : (
            <TrmSection>
              <div className="text-center d-flex resize">
                <TitleBalance weight={"600"} font={"1em"}>
                  TRM:
                </TitleBalance>
                <ValueBalance className="resize-value">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(trm)}
                </ValueBalance>
              </div>
            </TrmSection>
          )}

          <BalanceInterval balances={balances} />

          {/* <HeaderIcon as={MdQuestionAnswer} color="#f9a621" /> */}
          {/*este es el botón para abrir el panel de notificaciones */}
          <Tooltip placement="auto" label="Abrir notificaciones">
            <HeaderIcon
              onClick={() => {
                openNoti();
              }}
              as={MdNotifications}
              color="#1f7ac3"
            />
          </Tooltip>

          {/* este es el contador de notificaciones */}
          {newNotification && newNotification !== 0 ? (
            <Badge ml="-4" mt="-5" colorScheme="red">
              {newNotification}
            </Badge>
          ) : null}

          <Image
            src={HeaderUserImage}
            alt="pic user"
            boxSize="24px"
            borderRadius="full"
            mr="5px"
            ml="10px"
          />
          <Menu closeOnSelect={false}>
            <MenuButton className="d-flex">
              <span>{dataUser?.username}</span>
              <HeaderIcon as={MdKeyboardArrowDown} color="#333333" />
            </MenuButton>

            <MenuList>
              <NavLink
                className={({ isActive }) => isActive && ""}
                to="/profilee"
              >
                <ProfileItem>
                  <TitleProfile>Ver perfil</TitleProfile>
                </ProfileItem>
              </NavLink>
              <MenuItem>
                <Logout />
              </MenuItem>

              {/* "getScales", */}
              {allAccess ? (
                <MenuItem>
                  <AccordionSimple title={"Seleccione báscula"}>
                    <FormControl>
                      <RadioGroup>
                        <HStack>
                          <div className="row">
                            <div className="col ms-4">
                              {dataBasculas &&
                                dataBasculas.map((items, index) => (
                                  <AccordionPanel key={index}>
                                    <Radio
                                      id={items.id}
                                      value={items.name}
                                      onChange={handleChangeCheck}
                                    >
                                      {items.name}
                                    </Radio>
                                  </AccordionPanel>
                                ))}
                            </div>
                          </div>
                        </HStack>
                      </RadioGroup>
                    </FormControl>
                  </AccordionSimple>
                </MenuItem>
              ) : allAccess === false && myPermission?.includes("getScales") ? (
                <MenuItem>
                  <AccordionSimple title={"Seleccione báscula"}>
                    <FormControl>
                      <RadioGroup>
                        <HStack>
                          <div className="row">
                            <div className="col ms-4">
                              {dataBasculas &&
                                dataBasculas.map((items, index) => (
                                  <AccordionPanel key={index}>
                                    <Radio
                                      id={items.id}
                                      value={items.name}
                                      onChange={handleChangeCheck}
                                    >
                                      {items.name}
                                    </Radio>
                                  </AccordionPanel>
                                ))}
                            </div>
                          </div>
                        </HStack>
                      </RadioGroup>
                    </FormControl>
                  </AccordionSimple>
                </MenuItem>
              ) : (
                ""
              )}
              {authUser.is_commerce === 1 && (
                <div className="select-stile">
                  <InputSelect
                    tag={"Zonas Francas"}
                    size={100}
                    data={dataFreeZone}
                    set={(e) => {
                      setValueFreeZone(e);
                      changeFreeZone(e?.value, e);
                    }}
                    value={valueFreeZone}
                    menuPosition='auto' /*  */
                  />
                </div>
              )}
            </MenuList>
          </Menu>
        </HeaderSection>
      </HeaderStyled>
    </>
  );
};

export default Header;
