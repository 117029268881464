/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import InputSelect, {
  InputEmpresaRev,
  InputLicenciaUnit,
  InputPermisosG,
  InputRolesG,
  InputZonasFUser,
  InputSelectMulti,
} from "components/InputSelect/InputSelect";
import PasswordField from "components/PasswordField/PasswordField";
import { Form, Formik } from "formik";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { Continuar, Cancelar } from "pages/RefProductInter/Stilos";

import * as Yup from "yup";
import { useContext } from "react";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { __LicensesAllUc } from "request/configuration/__AddLicenses";
import { __RolesNotPage } from "request/configuration/__Roles";
import { __PermisosNotPage } from "request/configuration/__Permissions";
import { __ZonasFranca } from "request/configuration/__ZonasFranca";
import { IconGeneric, IconInModal } from "styled/Icons.styled";
import { RiLockPasswordFill } from "react-icons/ri";
import "../../Gestion_User/form/stilos.css";
import { ChangePassword } from "./ChangePassword";
import { useSelector } from "react-redux";
import { HiOutlinePlus } from "react-icons/hi";
import LicensesForm from "./LicensesForm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import environment from "constants/apiConst";
import { LabelStyledLink } from "components/addProduct/CreateTemplate.styled";
import { RolForm } from "./RolForm";
import { __SHOW_COUNT_USER } from "request/configuration/__CountUser";
import { __Dependency_Not_Paginate } from "request/configuration/__Dependency";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useQueryClient } from "@tanstack/react-query";
import isArrayNotEmpty, { isArray } from "utils/type-check-utils";

const ConfirnmPasswordSchema = Yup.object().shape({
  password_confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "¡Las contraseñas deben coindicir!"),
});

export const FormAccesUser = () => {
  // variables a capturar del select//
  const [userUSerQ, setUserUSerQ] = useState(null);
  const [valor, setValor] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [zonasF, setZonasF] = useState([]);
  const [selEmpresRevi, setSelEmpresRevi] = useState([]);

  // datas de los select//
  const [selUSerQ, setSelUSerQ] = useState([]);
  const [empresRevi, setEmpresRevi] = useState([]);
  const [selectRol, setSelectRol] = useState([]);
  const [userCa, setUserCa] = useState([]);
  const [permisosSele, setPermisosSele] = useState([]);
  const [zonaF, setZonaF] = useState([]);

  // / check box ///
  const [limitaciones, setLimitaciones] = useState(1);
  const [comercio, setComercio] = useState(0);
  const [admin, setAdmin] = useState(0);
  const [notif, setNotif] = useState(0);
  const [staff, setStaff] = useState(0);
  const [allper, setAllper] = useState(0);
  const [status, setStatus] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [defectoRoles, setDefectoRoles] = useState(null);
  const [defectoEmp, setDefectoEmp] = useState(null);
  const [defectoZona, setDefectoZona] = useState(null);
  const [rolesSelect, setRolesSelect] = useState([]);
  const [dataDependency, setDataDependency] = useState([]);
  const [valueDependency, setValueDependecy] = useState(null);
  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    onCloseModal,
    dataToEdit,
    createData,
    editData,
    setRequestCreateLicense,
    setidUserQualified,
    NewLicense,
    isOpenRol,
    onOpenRol,
    onCloseRol,
  } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    userC();
    EmpresasRevision();
    PermisosNotPage();
    ZonasFranca();
    RolesNotPage();
  }, [token, NewLicense]);

  useEffect(() => {
    RolesNotPage();
  }, [NewLicense]);

  useEffect(() => {
    if (userUSerQ && userUSerQ.value) LicenciasSelect();
  }, [userUSerQ, NewLicense]);

  const LicenciasSelect = async () => {
    setidUserQualified(userUSerQ.value);
    try {
      const res = await __LicensesAllUc(token, userUSerQ.value);
      setUserCa(res?.data?.status?.data);
    } catch (error) { }
  };

  const PermisosNotPage = async () => {
    try {
      const res = await __PermisosNotPage(token);
      setPermisosSele(res?.data?.status?.data);
    } catch (error) { }
  };

  const ZonasFranca = async () => {
    try {
      const res = await __ZonasFranca(token);
      const infoUC = res?.data?.status?.data.map((item) => ({
        label: item.name_freeZone,
        value: item?.id,
      }));

      setZonaF(infoUC);
    } catch (error) { }
  };

  const userC = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const selUQ = res?.data?.status?.data.map((item) => ({
        value: item.id,
        label: item.name_qualified,
      }));
      setSelUSerQ(selUQ);
    } catch (error) { }
  };

  const EmpresasRevision = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUC = res?.data?.status?.data.map((item) => ({
        label: item.name_qualified,
        value: item?.id,
      }));

      setEmpresRevi(infoUC);
    } catch (error) { }
  };

  const RolesNotPage = async () => {
    try {
      const res = await __RolesNotPage(token);
      setSelectRol(res?.data?.status?.data);
    } catch (error) { }
  };

  const initialValues = {
    password: "",
    password_confirm: "",
  };

  const [valuesF, handleInputChange, reset, setValues] = useForm({
    nickname: "",
    username_efranco: "",
    lastname_person: "",
    name_person: "",
  });

  const { nickname, lastname_person, name_person } = valuesF || {};

  const queryClient = useQueryClient();

  const cancelQuery = () => {
    queryClient.invalidateQueries({ queryKey: ["userAccess"] });
  };
  const [valuePermissions, setPermissions] = useState([]);

  const handleSubmit = async (values) => {
    // / para la creacion de una usuarios de accesos estaremos validando si sera creado con permisos
    // / de superadmin, si es asi solo enviaremos el primer array mas las contraseña de ser lo contrario
    // / enviaremos la informacion del array que tiene data y la de no notAmin

    // / asi mismo para editar, validaremos si es admin y aplicaran las mismas condiciones

    const { password, password_confirm } = values;
    delete valuesF.qualified_user;
    delete valuesF.permission;
    delete valuesF.rol;

    const zfRew = [];
    selEmpresRevi.forEach(({ value }) => zfRew.push(value));
    const zf = [];
    zonasF.forEach(({ value }) => zf.push(value));

    const data = {
      ...valuesF,
      qualifiedUser_id: userUSerQ?.value ?? "",
      is_superadmin: admin,
      user_free_zones: zf,
      view_qualified_users: zfRew,
      is_commerce: comercio,
      session_limit: limitaciones,
      all_notify: notif,
      dependency_id: valueDependency?.value ?? "",
    };

    const hasNonEmptyPermissions =
      isArray(valuePermissions) && isArrayNotEmpty(valuePermissions);

    const transformedIds = hasNonEmptyPermissions
      ? valuePermissions.map((item) => item.value)
      : [];


    if (dataToEdit) {
      if (admin) {
        const adminPermi = {
          ...data,
          permissions: transformedIds,
          status: 1,
          staff_status: 1,
        };
        editData(adminPermi, cancelQuery);
      } else {
        const NotAdmin = {
          ...data,
          rol_id: rolesSelect.value,
          all_permissions: allper,
          license_id: valor.value,
          permissions: transformedIds,
          status,
          staff_status: staff,
        };
        editData(NotAdmin, cancelQuery);
      }
    } else {

      if (!password) {
        return openAlert("La contraseña es obligatoria", "error");
      }
      if (!password_confirm) {
        return openAlert("La confirmación de la contraseña es obligatoria", "error");
      }
      
      if (admin) {
        const administradores = {
          ...data,
          password,
          password_confirm,
          status: 1,
          staff_status: 1,
        };

        createData(administradores, cancelQuery);
        // console.log(administradores);
      } else {
        const NotAdmin = {
          ...data,
          rol_id: rolesSelect.value,
          status,
          staff_status: staff,
          all_permissions: allper,
          license_id: valor.value,
          permissions: transformedIds,
          password,
          password_confirm,
        };
        createData(NotAdmin, cancelQuery);
        console.log(NotAdmin);
      }
    }
  };

  const Upload = () => {
    document.getElementById("display").click();
  };

  const handleCheckboxChange = (event) => {
    const { value: checkboxLabel, checked: isChecked } = event.target;

    const checkboxActions = {
      "Limitar sesiones": () => setLimitaciones(isChecked ? 1 : 0),
      "Es comercio": () => setComercio(isChecked ? 1 : 0),
      Superadmin: () => setAdmin(isChecked ? 1 : 0),
      Notif: () => setNotif(isChecked ? 1 : 0),
      staff: () => setStaff(isChecked ? 1 : 0),
      allPer: () => setAllper(isChecked ? 1 : 0),
      status: () => setStatus(isChecked ? 1 : 0),
    };

    if (checkboxActions[checkboxLabel]) checkboxActions[checkboxLabel]();
  };

  // const addOrRemoveZona = (e) => {
  //   if (!e.target.checked) {
  //     let remover = parseInt(e.target.value);
  //     removeItemFromArr(zonasF, remover);
  //   } else {
  //     setZonasF((prev) => [...prev, parseInt(e.target.value)]);
  //   }
  // };

  // const addOrRemoveEmp = (e) => {
  //   if (!e.target.checked) {
  //     let remover = parseInt(e.target.value);
  //     removeItemFromArr(selEmpresRevi, remover);
  //   } else {
  //     setSelEmpresRevi((prev) => [...prev, parseInt(e.target.value)]);
  //   }
  // };

  const removeItemFromArr = (arr, item) => {
    const i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  };

  const CreateNewLicenseOrRol = (e) => {
    e.preventDefault();
    const who = e.target.id;
    if (who === "Nuevalicencia") {
      onOpenLicencia();
      setRequestCreateLicense(environment.LICENSES_CREATE);
    } else {
      setRequestCreateLicense(environment.ROL_CREATE);
      onOpenRol();
    }
  };

  const initialpos = async () => {
    try {
      const res = await __SHOW_COUNT_USER(token, dataToEdit.id);
      if (res.data.status?.code === 403) {
        return openAlert(res?.data?.status?.message, "error");
      }
      const response = res.data.status.data;
      const hasNonEmptyPermissions =
        isArray(response?.permission) && isArrayNotEmpty(response?.permission);

      const transformedIds = hasNonEmptyPermissions
        ? response?.permission.map((item) => item.id)
        : [];

      const transformedPermissions = hasNonEmptyPermissions
        ? response?.permission.map((permission) => ({
          value: permission.id,
          label: permission.description_permission,
        }))
        : [];

      if (hasNonEmptyPermissions) {
        setPermisos(transformedIds);
        setPermissions(transformedPermissions);
      }

      setValues(response);
      //setPermisos
      setValueDependecy({
        value: response?.dependency_id,
        label: response?.dependency,
      });

      // response?.view_qualified_users.map((items, index) =>
      //   setSelEmpresRevi((prev) => [...prev, items.id])
      // );
      //   response?.user_free_zones.map((items, index) =>
      //   setZonasF((prev) => [...prev, items.id])
      // );

      // // estados view////
      setNotif(response?.all_notify);
      setDefectoEmp(response?.view_qualified_users);
      setDefectoZona(response?.user_free_zones);
      response?.qualified_user &&
        setUserUSerQ({
          value: response?.qualified_user?.id,
          label: response?.qualified_user?.name_qualified,
        });
      setComercio(response?.is_commerce);
      setLimitaciones(response?.session_limit);
      setStaff(response?.staff_status);
      setStatus(response?.status);

      response?.view_qualified_users.map(({ name_qualified, id }) =>
        setSelEmpresRevi((prev) => [
          ...prev,
          { label: name_qualified, value: id },
        ])
      );

      response?.user_free_zones.map(({ name_freeZone, id }) =>
        setZonasF((prev) => [...prev, { label: name_freeZone, value: id }])
      );

      if (response?.rol.id === 1) {
        setAdmin(1);
      } else {
        setRolesSelect({
          value: response?.rol.id,
          label: response?.rol.name_rol,
        });
        setValor({
          value: response?.license_user.id,
          label: response?.license_user.name_license,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("permisos", permisos);
  }, [permisos]);

  const getCountry = useCallback(async () => {
    try {
      const res = await __Dependency_Not_Paginate(token);
      const indoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.dependency,
      }));
      setDataDependency(indoCountry);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  useEffect(() => {
    if (dataToEdit) {
      initialpos();
    } else {
      setValues(valuesF);
    }
  }, [dataToEdit]);

  const {
    isOpen: isOpenLicencia,
    onOpen: onOpenLicencia,
    onClose: onCloseLicencia,
  } = useDisclosure();

  const handleClose = () => {
    onCloseModal()
  }

  return (
    <>
      <ModalGeneric
        isOpen={isOpenLicencia}
        onOpen={onOpenLicencia}
        onClose={onCloseLicencia}
        size={"xl"}
      >
        <LicensesForm onClose={onCloseLicencia} userUSerQ={userUSerQ} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRol}
        onOpen={onOpenRol}
        onClose={onCloseRol}
        size={"xl"}
      >
        <RolForm onCloseRol={onCloseRol} />
      </ModalGeneric>

      <ChangePassword
        isOpen={isOpen}
        onClose={onClose}
        informacion={dataToEdit}
      />

      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? " Edita cuenta de usuario"
          : "Agregar nueva cuenta de usuario"}
      </Text>
      <Divider my={2} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <LabelGroup
          name={"name_person"}
          onChange={handleInputChange}
          value={name_person || ""}
          tag={"Nombre"}
          size={48}
          desicion={false}
          maxLength={80}
        />
        <LabelGroup
          name={"lastname_person"}
          onChange={handleInputChange}
          value={lastname_person || ""}
          tag={"Apellido"}
          size={48}
          desicion={false}
          maxLength={80}
        />
      </div>

      <Formik
        validationSchema={!dataToEdit ? ConfirnmPasswordSchema : ""}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            {!dataToEdit ? (
              <div
                className="my-4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2rem",
                }}
              >
                <div style={{ width: "48%" }}>
                  <PasswordField
                    size="md"
                    name="password"
                    placeholder="Contraseña"
                  />
                </div>

                <div style={{ width: "48%" }}>
                  <PasswordField
                    size="md"
                    name="password_confirm"
                    placeholder="Repita su contraseña"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <Text fontSize="md" my={2} style={{ fontWeight: 550 }}>
              Perfil Usuario
            </Text>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LabelGroup
                name={"nickname"}
                onChange={handleInputChange}
                value={nickname || ""}
                tag={"Nombre de usuario en Efranco"}
                size={48}
                desicion={false}
                maxLength={80}
              />
              <div style={{ width: "48%" }}>
                <InputSelect
                  tag={"Empresa"}
                  data={selUSerQ || []}
                  set={setUserUSerQ}
                  value={userUSerQ}
                />
              </div>
            </div>
            <div
              className="my-5"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                defaultChecked
                isChecked={!!limitaciones}
                color="#000"
                onChange={handleCheckboxChange}
                value="Limitar sesiones"
                className="me-3"
              >
                ¿Limitar sesiones?
              </Checkbox>
              <Checkbox
                isChecked={!!comercio}
                color="#000"
                onChange={handleCheckboxChange}
                value="Es comercio"
              >
                ¿Es comercio?
              </Checkbox>
            </div>

            {comercio === 1 && (
              <div>
                <InputSelect
                  tag={"Dependencia"}
                  size={50}
                  data={dataDependency}
                  set={setValueDependecy}
                  value={valueDependency === null ? "" : valueDependency}
                />
              </div>
            )}

            <input type="file" id="display" />
            <Text
              className="my-5"
              fontSize="md"
              mb={1}
              style={{ fontWeight: 500 }}
            >
              Firma Adjunto
              <Button
                bg="#03C39A"
                height="24px"
                width="170px"
                className="mx-4"
                color="#fff"
                borderRadius="2px"
                _hover={{ bg: "#0bf1c0" }}
                onClick={Upload}
              >
                Agregar Adjunto
              </Button>
            </Text>
            <div
              className="my-5"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                isChecked={!!admin}
                onChange={handleCheckboxChange}
                value="Superadmin"
              >
                Superadmin
              </Checkbox>
              <Checkbox
                onChange={handleCheckboxChange}
                value="status"
                isChecked={!!(status || admin)}
                isDisabled={!!admin}
                className="mx-2"
              >
                Activo
              </Checkbox>

              <Checkbox
                isChecked={!!(staff || admin)}
                isDisabled={!!admin}
                onChange={handleCheckboxChange}
                value="staff"
              >
                Staff Status
              </Checkbox>

              <Checkbox
                isDisabled={!!admin}
                onChange={handleCheckboxChange}
                value="allPer"
                className="mx-2"
              >
                Todos los permisos
              </Checkbox>
              <Checkbox
                isChecked={!!notif}
                onChange={handleCheckboxChange}
                value="Notif"
              >
                Notif todas las presentaciones
              </Checkbox>
            </div>

            <InputLicenciaUnit
              partidas={userCa || []}
              tag={"Licencia"}
              size={45}
              setValor={setValor}
              decision={admin}
              mendigo={valor}
            >
              {userUSerQ && (
                <LabelStyledLink
                  id="Nuevalicencia"
                  onClick={(e) => CreateNewLicenseOrRol(e)}
                >
                  Nueva licencia
                  <IconInModal
                    as={HiOutlinePlus}
                    className="ms-1"
                    id="Nuevalicencia"
                  // onClick={(e) => CreateNewLicenseOrRol(e)}
                  />
                </LabelStyledLink>
              )}
            </InputLicenciaUnit>

            <div
              className="my-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <InputRolesG
                partidas={selectRol}
                tag={"Rol"}
                size={45}
                setValor={setRolesSelect}
                decision={admin}
                mendigo={rolesSelect}
              // valor={rol}
              />

              <InputPermisosG
                partidas={permisosSele}
                values={valuePermissions}
                setValue={setPermissions}
                tag={"Permisos"}
                size={45}
                setValor={setPermisos}
                decision={admin}
              />
            </div>

            {/*kety pidio que se comentara esto el dia 13/08/2024 por que no hace sentido que se cree un rol desde aca */}

            {/* <LabelStyledLink 
              onClick={(e) => CreateNewLicenseOrRol(e)}
            >
              Nuevo rol
              <IconInModal
                as={HiOutlinePlus}
                className="ms-1"
                id="Nuevorol"
                onClick={(e) => CreateNewLicenseOrRol(e)}
              />
            </LabelStyledLink> */}

            <div
              className="my-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {dataToEdit && defectoRoles
                ? defectoRoles.map((items, index) => (
                  <div>
                    <Checkbox
                      className="mx-3"
                      value={items.user_id}
                      // onChange={actState}
                      defaultIsChecked={true}
                    >
                      {items?.nickname}
                    </Checkbox>
                  </div>
                ))
                : ""}
            </div>

            <div
              className="my-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "45%" }}>
                <InputSelectMulti
                  tag={"Empresa revisar"}
                  data={empresRevi}
                  set={setSelEmpresRevi}
                  value={selEmpresRevi}
                />
              </div>

              {/* <InputEmpresaRev
                partidas={empresRevi}
                tag={"Empresa revisar"}
                size={45}
                setValor={setSelEmpresRevi}
                ediciones={dataToEdit}
              /> */}
              <div style={{ width: "45%" }}>
                <InputSelectMulti
                  tag={"Zona Franca"}
                  data={zonaF}
                  set={setZonasF}
                  value={zonasF}
                />
              </div>

              {/* <InputZonasFUser
                partidas={zonaF}
                tag={"Zona Franca"}
                size={45}
                setZonasF={setZonasF}
                ediciones={dataToEdit}
              /> */}
            </div>

            {/* <div
              className="my-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {dataToEdit ? (
                <div>
                  <h1 className="my-3">Empresas Rev.</h1>
                  {defectoEmp?.map((items, index) => (
                    <div key={index}>
                      <Checkbox
                        className="mx-3"
                        value={items.id}
                        onChange={addOrRemoveEmp}
                        defaultIsChecked={true}
                      >
                        {items.name_qualified}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}

              <div style={{ width: "45%" }}>
                {dataToEdit ? (
                  <div>
                    <h1 className="my-3">Zona franca</h1>
                    {defectoZona?.map((items, index) => (
                      <div key={index}>
                        <Checkbox
                          className="mx-3"
                          value={items.id}
                          onChange={addOrRemoveZona}
                          defaultIsChecked={true}
                        >
                          {items.name_freeZone}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}

            {dataToEdit ? (
              <Text
                className="my-3"
                fontSize="md"
                id="changepasword"
                type="button"
                onClick={onOpen}
                style={{ fontWeight: 700 }}
              >
                ¿Olvidaste tu contraseña?{" "}
                <IconGeneric as={RiLockPasswordFill} />
              </Text>
            ) : (
              ""
            )}
            <Flex className="mt-5">
              <Cancelar type="button" onClick={handleClose}>Cancelar</Cancelar>
              <Spacer />
              <Continuar type="submit">
                {dataToEdit ? " Editar" : "Guardar"}
              </Continuar>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};
