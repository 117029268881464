import { Flex, Spacer, TabPanel } from "@chakra-ui/react";
import React, { useState, useContext, useEffect, useCallback } from "react";

// import { useRef } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import TableOrder from "components/Tables/TableOrder";
import TbodyType from "./TbodyType";
import { TabsAdvanced } from "components/Tabs-Pestañas/TabsAdvanced";
import TBodyTypeAlert from "./TBodyTypeAlert";

import { AuthContext } from "context/AuthContext";
import TbodyNotifEmpresa from "./TbodyNotifEmpresa";
import environment from "constants/apiConst";
import { __Get } from "request/Petitions/__Get";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { useGlobalContext } from "context/GlobalContext";

export const ConfigNotification = ({ onClose, restConfi, StateReset }) => {
  const [loading, setLoading] = useState(false);
  const { authUser } = useContext(AuthContext);
  const [positionView, setPositionView] = useState(0);
  const token = useSelector((state) => state.auth.dataUser.token);


  const { requestData } = useGlobalContext();

  const { data: dataAlerts, isLoading: isLoadingAlerts } = useQuery({
    queryKey: [queryEnv?.SHOW_ALERT_CONFIG, positionView],
    queryFn: () => requestData({ Endpoint: environment.SHOW_CONFI_NOTIFICATION }),
  })


  const { data: dataNotifications, isLoading: isLoadingNotifications } = useQuery({
    queryKey: [queryEnv?.GET_NOTIFICATIONS_CONFIG],
    queryFn: () => requestData({ Endpoint: environment.SHOW_CONFI_ALERT }),

  })

  const { data: dataConfigEnterprise, isLoading: isLoadingConfigEnterprise } = useQuery({
    queryKey: [queryEnv?.SHOW_CONFI_NOTIF_EMPRESA],
    queryFn: () => requestData({ Endpoint: environment.SHOW_CONFI_NOTIF_EMPRESA }),

  })

  const handleCambio = (index) => {
    setPositionView(index);

  };

  const headTable = [
    { name: "Nombre", order: null },
    { name: "Correo", order: null },
    { name: "días antes", order: null },
    { name: "L", order: null },
    { name: "M", order: null },
    { name: "w", order: null },
    { name: "J", order: null },
    { name: "V", order: null },
    { name: "S", order: null },
    { name: "D", order: null }
  ];

  const headTableTwo = [
    { name: "Nombre", order: null },
    { name: "Correo", order: null }
  ];

  const headTableTREE = [
    { name: "Nombre", order: null },
    { name: "NOTIFICAR", order: null },
    { name: "Correo", order: null }
  ];

  const headerTabs = ["Tipo de alerta", "Tipo de notificaciones"];
  const headerTabsUc = [
    "Tipo de alerta",
    "Tipo de notificaciones",
    "Notificaciones de la Empresa"
  ];

  return (
    <>
      <div className="row">
        {/* <div className="col-2">
          <Title size="14px">Canales de notificación</Title>
          <SubTitle size="12px">Medios de notificación</SubTitle>

          <ul className="noStyle mt-2">
            {medios.map((items, index) => (
              <li key={index}>
                <Checkbox
                  isInvalid
                  id={items.id}
                  value={items.value}
                  className="mb-2 check-box"
                  // onChange={selectVar}
                  // defaultIsChecked={
                  //   dafaulActive &&
                  //   dafaulActive.find((element) =>
                  //     element === items.value ? true : false
                  //   )
                  // }
                >
                  <span className="name-label">{items.label}</span>
                </Checkbox>
              </li>
            ))}
          </ul>

          <Title size="14px" className="mt-3">
            Tipos de notificaciones
          </Title>
          <SubTitle size="12px">Tipos que seran notificados</SubTitle>
          <Title size="12px" className="mt-4">
            Email
          </Title>

          <ul className="noStyle mt-2">
            {medios.map((items, index) => (
              <li key={index}>
                <Checkbox
                  isInvalid
                  id={items.id}
                  value={items.value}
                  className="mb-2 check-box"
                  // onChange={selectVar}
                  // defaultIsChecked={
                  //   dafaulActive &&
                  //   dafaulActive.find((element) =>
                  //     element === items.value ? true : false
                  //   )
                  // }
                >
                  <span className="name-label">{items.label}</span>
                </Checkbox>
              </li>
            ))}
          </ul>
        </div> */}
        <div className="col-12">
          {/* <Title size="14px">Tipo de alerta</Title>
          <SubTitle size="12px">Proximo a expirar</SubTitle> */}

          <TabsAdvanced
            handleCambio={handleCambio}
            arrayHead={authUser.is_qualified_user ? headerTabsUc : headerTabs}
          >
            <TabPanel>
              <TableOrder
                thead={headTable}
                data={dataAlerts?.data?.status?.data || []}
                setData={null}
                loading={loading}
                tbodyData={TbodyType}
              />
            </TabPanel>
            <TabPanel>
              <TableOrder
                thead={headTableTwo}
                data={dataNotifications?.data?.status?.data || []}
                setData={null}
                loading={loading}
                tbodyData={TBodyTypeAlert}
              />
            </TabPanel>
            {authUser.is_qualified_user && dataConfigEnterprise?.data?.status?.data && (
              <TabPanel>
                <TableOrder
                  thead={headTableTREE}
                  data={dataConfigEnterprise?.data?.status?.data || []}
                  setData={null}
                  loading={loading}
                  tbodyData={TbodyNotifEmpresa}
                />
              </TabPanel>
            )}
          </TabsAdvanced>

          {/* <SubTitle size="12px">Arrastre para ordenar</SubTitle> */}
          {/* <Title size="14px">Priorización</Title>
          <SubTitle size="12px">Arrastre para ordenar</SubTitle> */}
          {/* {list &&
            list.map((item, index) => (
              <div
                className="carddrap mt-2"
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                key={index}
                draggable
              >
                {item}

                <HeaderIcon
                  className="float-end"
                  as={GrDrag}
                  wd="15px"
                  color="#bababa"
                />

              </div>
            ))} */}
        </div>
      </div>

      <Flex>
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cancelar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => restConfi(positionView)}
        >
          Restaurar
        </ButtonStyled>
      </Flex>
    </>
  );
};
